<template>
	<select class="ui-select" :class="{ empty: !current_value }" @change="updateValue()" ref="input" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false">
		<option v-if="placeholder" disabled selected value="" class="placeholder">{{ placeholder }}</option>
		<option v-for="option in options" :value="option.id" :key="option.id" :selected="current_value === option.id"><strong v-if="option.name">{{ option.name }}</strong><span v-if="option.description"> - {{ option.description }}</span></option>
	</select>
</template>

<script>
export default {
	name: 'ui-select',
	data() {
		return {
			current_value: null
		}
	},
	props: {
		placeholder: { default: '' },
		options: { default: [] }
	},
	methods: {
		updateValue() {
			this.current_value = this.$el.value
			this.$emit('input', this.current_value)
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.ui-select
		font-size 16px
		
.ui-select
	display block
	width 100%
	height 100%
	border none
	background url(../../assets/img/chevron-down.svg) right 12px center no-repeat
	background-size 24px auto
	font inherit
	color inherit
	outline 0
	&.empty
		color alpha(#fff, 25%) !important
	option
		color dark
		outline 0
		&.placeholder
			color #999


</style>

<template>
	<form class="edit-player" @submit="submit($event)">
		<p class="title">Edit student</p>
		
		<div class="line">
			<ui-input v-model="student_number" placeholder="Student ID" />
		</div>
		<!-- <div class="line">
			<ui-input v-model="student_name" placeholder="Student name" />
		</div> -->
		<div class="buttons">
			<ui-button type="submit" v-hammer:tap="() => edit_player()" :loading="loading"><span>Submit</span></ui-button>
		</div>
	</form>
</template>

<script>
import { mapGetters } from 'vuex'
import UiInput from '@/components/ui/ui-input'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'edit-player-window',
	data() {
		return {
			loading: false,
			player: false,
			student_number: "",
			// student_name: ""
		}
	},
	computed: {
		...mapGetters([ 'app', 'game' ])
	},
	methods: {
		submit(e) {
			e.preventDefault()
			this.edit_player()
		},
		async edit_player() {
			if (this.player && this.student_number && !this.loading) {
				this.loading = true
				let player = await this.$store.dispatch('edit_player', { player: this.player, student_name: this.student_number, student_number: this.student_number })
				this.loading = false
				this.$router.go(-1)
			}
		}
	},
	mounted() {
		this.player = this.game.players.find((p) => { return p.id == this.$route.params.player_id })
		if (this.player) {
			this.student_number = this.player.student_number
			// this.student_name = this.player.student_name
		}
		this.$el.querySelector('input').focus()
	},
	components: { UiInput, UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.edit-player
	.title
		margin 0 0 16px 0
		font-size 1.8rem
		text-transform uppercase
		color blue_lighter
	.text
		margin 0 0 16px 0
		color gray_light
		font-size 1.6rem
		line-height 22px
	.line
		margin 0 0 16px 0
		width 100%
		.ui-input
			background-color alpha(#fff, 15%)
			height 48px
			width 100%
			border-radius 8px
			padding 0 24px
			font-size 1.6rem
			color #fff
			&:focus
				background-color alpha(#fff, 20%)
	.buttons
		>>> .ui-button .button
			border-radius 8px
			background-color #3FB143
			text-transform uppercase
			font-size 1.8rem
			font-family 'Josefin Sans'
			.loader
				border-radius 8px
				&.loading
					background-color #3FB143
			> span
				transform translateY(2px)

			
::-webkit-input-placeholder
	color alpha(#fff, 25%)
	opacity 1
::-moz-placeholder
	color alpha(#fff, 25%)
	opacity 1
:-ms-input-placeholder
	color alpha(#fff, 25%)
	opacity 1
:-moz-placeholder
	color alpha(#fff, 25%)
	opacity 1

	
</style>

<template>
	<form class="delete-multiple-rooms">
		<p class="title">Delete sessions</p>
		
		<div class="list-rooms">
			<div v-for="room in rooms" class="list-room" :key="room.id">
				<span class="name">{{ room.name }}</span>
				<span v-if="room.deleted" class="state">Deleted</span>
			</div>
		</div>

		<p v-if="ended && rooms.filter(r => r.deleted).length === rooms.length" class="success">All sessions have been deleted.</p>

		<div class="buttons">
			<ui-button v-if="!ended" v-hammer:tap="delete_rooms" :loading="loading" color="red">
				<span>Delete {{ rooms.length }} session<span v-show="rooms.length > 1">s</span></span>
			</ui-button>
			<ui-button v-else v-hammer:tap="() => $router.go(-1)">
				<span>Close</span>
			</ui-button>
		</div>
	</form>
</template>

<script>
import { mapGetters } from 'vuex'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'delete-multiple-rooms',
	data() {
		return {
			loading: false,
			ended: false
		}
	},
	computed: {
		...mapGetters([ 'app', 'master' ]),
		rooms() {
			if (this.$route.params.room_ids) {
				const room_ids = this.$route.params.room_ids.split(',')
				return this.master.rooms.filter((r) => room_ids.includes(r.id))
			}
			return []
		}
	},
	watch: {
		rooms: {
			handler() {
				this.loading = this.rooms.filter(r => r.deleted).length < this.rooms.length
				this.ended = !this.loading
			},
			deep: true
		}
	},
	methods: {
		async delete_rooms() {
			this.loading = true
			this.rooms.forEach(async (room) => {
				await this.$store.dispatch('delete_room', { room_id: room.id })
			})
		}
	},
	components: { UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.delete-multiple-rooms
	width 100%
	.title
		margin 0 0 16px 0
		font-size 1.8rem
		text-transform uppercase
		color blue_lighter
	.text
		margin 0 0 16px 0
		color gray_light
		font-size 1.6rem
		line-height 22px
	.success
		margin 0 0 24px 0
		font-size 1.4rem
		text-transform uppercase
		line-height 20px
		font-weight 700
		color green
	.list-rooms
		margin 0 0 16px 0
		.list-room
			width 100%
			padding 12px 16px 8px 16px
			margin 0 0 8px 0
			border-radius 8px
			background-color darken(blue_darker, 35%)
			text-align left
			font-family 'Josefin Sans'
			color blue_lighter
			font-size 2rem
			.state
				float right
				transform translateY(2px)
				text-transform uppercase
				font-size 1.4rem
				font-weight 700
				color #C18686
			
	.buttons
		>>> .ui-button
			&.inactive
				.button
					background-color gray
			.button
				border-radius 8px
				// background-color #3FB143
				text-transform uppercase
				font-size 1.8rem
				font-family 'Josefin Sans'
				.loader
					border-radius 8px
					// &.loading
					// 	background-color #3FB143
				> span
					transform translateY(2px)


	
</style>

<template>
	<div class="master-window">
		<div class="bg" v-hammer:tap="() => back()"></div>
		<div class="window-content" :style="{ 'max-width': window_content.max_width + 'px' }">
			
			<component :is="window_content.name" />

			<div class="close" v-hammer:tap="() => back()"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import NewRoom from '@/components/master/windows/new-room'
import EditPlayer from '@/components/master/windows/edit-player'
import Logout from '@/components/master/windows/logout'
import ValidateRoles from '@/components/master/windows/validate-roles'
import StartGame from '@/components/master/windows/start-game'
import NextGame from '@/components/master/windows/next-game'
import StopGame from '@/components/master/windows/stop-game'
import LaunchVideos from '@/components/master/windows/launch-videos'
import StartMultiple from '@/components/master/windows/start-multiple'
import DeleteMultipleRooms from '@/components/master/windows/delete-multiple-rooms'

export default {
	name: 'master-window',
	data() {
		return {
			
		}
	},
	props: {
		window_content: { default: false },
	},
	computed: {
		...mapGetters([ 'master' ])
	},
	methods: {
		back() {
			this.$router.go(-1)
		}
	},
	components: { NewRoom, EditPlayer, Logout, ValidateRoles, StartGame, NextGame, StopGame, LaunchVideos, StartMultiple, DeleteMultipleRooms }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.master-window
		.window-content
			padding 32px


.master-window
	fixed 0
	top 80px
	flex center center
	padding 32px
	z-index 4
	.bg
		absolute 0
		background-color alpha(blue_dark, 85%)
		// background-color blue_dark
	.window-content
		width 100%
		max-width 400px
		background-color blue_dark
		padding 40px
		border-radius 8px
		box-shadow 0 0 0 2px blue_lighter
		text-align center
		flex center center
		flex-direction column
		
		.close
			position absolute
			right 4px
			top 4px
			width 40px
			height 40px	
			cursor pointer
			background url(../../assets/img/close-round-white.svg) center center no-repeat
			background-size 100% auto
			

</style>

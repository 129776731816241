<template>
	<div class="stop-game">
		<p class="title">Pause the game ?</p>
		<p class="text">In-game progress will not be lost. Only the in-game clock and decision timer will be stopped. Players won't be able to interact with the game aside from consulting their profile.</p>
		<!-- <p class="question">This feature is not available for the demo.</p> -->
		<div class="buttons">
			<ui-button v-hammer:tap="() => no()" class="no"><span>No</span></ui-button>
			<ui-button v-hammer:tap="() => yes()" class="yes" :loading="loading"><span>Yes</span></ui-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'stop-game',
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters([ 'game' ])
	},
	methods: {
		no() {
			this.$router.go(-1)
		},
		async yes() {
			this.loading = true
			await this.$store.dispatch('change_room_state', { room_id: this.game.room.id, state: 'paused' })
			this.loading = false
			this.$router.go(-1)
		}
	},
	components: { UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.stop-game
	.title
		margin 0 0 24px 0
		padding 0 0 24px 0
		font-size 1.8rem
		text-transform uppercase
		color blue_lighter
		border-bottom 2px solid blue_lighter
	.text
		margin 0 0 24px 0
		color gray_light
		font-size 1.6rem
		line-height 22px
	.question
		margin 0 0 24px 0
		color blue_lighter
		font-size 1.6rem
		text-transform uppercase
		line-height 22px
		color red
	.buttons
		margin 24px 0 0 0
		>>> .ui-button
			&.no
				.button
					background-color #B93636
			.button
				margin 0 8px
				border-radius 8px
				background-color #3FB143
				text-transform uppercase
				font-size 1.8rem
				font-family 'Josefin Sans'
				.loader
					border-radius 8px
					&.loading
						background-color #3FB143
				> span
					transform translateY(2px)

	
</style>

<template>
	<form class="start-multiple">
		<p class="title">Start multiple games</p>
		
		<div class="list-rooms">
			<div v-for="room in rooms" class="list-room" :key="room.id">
				<span class="name">{{ room.name }}</span>
				<span class="state" :class="room.state">{{ room.state }}</span>
			</div>
		</div>

		<p v-if="!all_is_ready && !ended" class="error">You can't start games that are already running.</p>
		
		<div class="buttons">
			<ui-button v-if="!ended" :inactive="!all_is_ready" v-hammer:tap="() => start()" :loading="loading">
				<span>Start {{ rooms.length }} games</span>
			</ui-button>
			<ui-button v-else v-hammer:tap="() => $router.go(-1)">
				<span>Close</span>
			</ui-button>
		</div>
	</form>
</template>

<script>
import { mapGetters } from 'vuex'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'start-multiple',
	data() {
		return {
			loading: false,
			ended: false
		}
	},
	computed: {
		...mapGetters([ 'app', 'master' ]),
		rooms() {
			if (this.$route.params.room_ids) {
				const room_ids = this.$route.params.room_ids.split(',')
				return this.master.rooms.filter((r) => room_ids.includes(r.id))
			}
			return []
		},
		all_is_ready() {
			// return true
			return this.rooms.filter((r) => r.state === 'stopped' || r.state === 'paused' || r.state === 'ending').length === this.rooms.length
		}
	},
	watch: {
		rooms: {
			handler() {
				this.loading = this.rooms.filter(r => r.state === 'running').length < this.rooms.length
				this.ended = !this.loading
			},
			deep: true
		}
	},
	methods: {
		async start() {
			if (this.all_is_ready) {
				this.loading = true
				this.rooms.forEach(async (room) => {
					if (room.state === 'paused') {
						this.$store.dispatch('restart_game_session', { room_id: room.id })
					}
					else {
						await this.$store.dispatch('start_game_session', { room_id: room.id })
					}
				})
			}
		}
	},
	components: { UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.start-multiple
	width 100%
	.title
		margin 0 0 16px 0
		font-size 1.8rem
		text-transform uppercase
		color blue_lighter
	.text
		margin 0 0 16px 0
		color gray_light
		font-size 1.6rem
		line-height 22px
	.error
		margin 0 0 24px 0
		font-size 1.4rem
		text-transform uppercase
		line-height 20px
		font-weight 700
		color red
	.list-rooms
		margin 0 0 16px 0
		.list-room
			width 100%
			padding 12px 16px 8px 16px
			margin 0 0 8px 0
			border-radius 8px
			background-color darken(blue_darker, 35%)
			text-align left
			font-family 'Josefin Sans'
			color blue_lighter
			font-size 2rem
			.state
				float right
				transform translateY(2px)
				text-transform uppercase
				font-size 1.4rem
				font-weight 700
				&.stopped, &.ending
					color #C18686
				&.running
					color #48B871
				&.paused
					color #e5a207
			
	.buttons
		>>> .ui-button
			&.inactive
				.button
					background-color gray
			.button
				border-radius 8px
				background-color #3FB143
				text-transform uppercase
				font-size 1.8rem
				font-family 'Josefin Sans'
				.loader
					border-radius 8px
					&.loading
						background-color #3FB143
				> span
					transform translateY(2px)


	
</style>

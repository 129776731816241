<template>
	<div class="lock-roles">
		<p class="title">Validating the roles</p>
		<p class="text">By locking the roles, you'll be sending them to their corresponding players (if said player has access to the game room). Players will then be able to consult their character profile in order to prepare for the game.</p>
		<p class="text">You can always unlock the roles afterwards if modifications were made.</p>
		<p class="question">Would you like to send the players their roles?</p>
		<div class="buttons">
			<ui-button v-hammer:tap="() => no()" class="no"><span>No</span></ui-button>
			<ui-button v-hammer:tap="() => yes()" class="yes" :loading="loading"><span>Yes</span></ui-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'lock-roles',
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters([ 'master' ])
	},
	methods: {
		no() {
			this.$router.go(-1)
		},
		async yes() {
			this.loading = true
			let ok = await this.$store.dispatch('set_players_roles')
			this.loading = false
			this.$router.go(-1)
		}
	},
	components: { UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.lock-roles
	.title
		margin 0 0 24px 0
		padding 0 0 24px 0
		font-size 1.8rem
		text-transform uppercase
		color blue_lighter
		border-bottom 2px solid blue_lighter
	.text
		margin 0 0 24px 0
		color gray_light
		font-size 1.6rem
		line-height 22px
	.question
		margin 0 0 24px 0
		color blue_lighter
		font-size 1.6rem
		text-transform uppercase
		line-height 22px
	.buttons
		margin 24px 0 0 0
		>>> .ui-button
			&.no
				.button
					background-color #B93636
			.button
				margin 0 8px
				border-radius 8px
				background-color #3FB143
				text-transform uppercase
				font-size 1.8rem
				font-family 'Josefin Sans'
				.loader
					border-radius 8px
					&.loading
						background-color #3FB143
				> span
					transform translateY(2px)

	
</style>

<template>
	<div class="launch-videos">
		<p class="title">Launch video</p>
		<p class="text">You can launch videos for players.</p>
		
		<div class="videos">
			<div class="video">
				<div class="name"><ui-icon name="play" :size="24" />Intro</div>
				<div class="buttons">
					<ui-button class="open" v-hammer:tap="() => open_video('intro')">Open</ui-button>
					<ui-button class="send" :loading="loading.intro" v-hammer:tap="() => send_video('intro')">Send to students</ui-button>
				</div>
			</div>
			<div class="video">
				<div class="name"><ui-icon name="play" :size="24" />Tutorial</div>
				<div class="buttons">
					<ui-button class="open" v-hammer:tap="() => open_video('tutorial')">Open</ui-button>
					<ui-button class="send" :loading="loading.tutorial" v-hammer:tap="() => send_video('tutorial')">Send to students</ui-button>
				</div>
			</div>
			<!-- <div class="video">
				<div class="name"><ui-icon name="play" :size="24" />End game</div>
				<div class="buttons">
					<ui-button class="open" v-hammer:tap="() => open_video('outro')">Open</ui-button>
					<ui-button class="send" :loading="loading.outro" v-hammer:tap="() => send_video('outro')">Send to students</ui-button>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'new-room-window',
	data() {
		return {
			loading: {
				intro: false,
				tutorial: false,
				outro: false
			}
		}
	},
	computed: {
		...mapGetters([ 'game' ])
	},
	methods: {
		open_video(slug) {
			if (this.game.scenario && this.game.scenario[slug]) {
				let win = window.open(this.game.scenario[slug], '_blank')
				win.focus()
			}
		},
		async send_video(slug) {
			if (!this.loading[slug]) {
				this.loading[slug] = true
				let response = await Apollo.mutate('SET_ROOM_VIDEO_EVENT', { room_id: parseInt(this.game.room.id), key: slug })
				if (response && response.id) {
					this.$store.dispatch('add_notification', { status: "success", message: "Sent!" })
				} else
					this.$store.dispatch('add_notification', { status: "error", message: "Unable to send video" })
				
				this.loading[slug] = false
			}
		}
	},
	components: { UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.launch-videos
	width 100%
	.title
		width 100%
		margin 0 0 24px 0
		padding 0 0 24px 0
		font-size 1.8rem
		text-transform uppercase
		color blue_lighter
		border-bottom 2px solid blue_lighter
	.text
		width 100%
		margin 0 0 24px 0
		color gray_light
		font-size 1.6rem
		line-height 22px
	.videos
		width 100%
		.video
			width 100%
			flex space-between center
			padding 24px 0
			border-bottom 1px dotted blue_light
			&:last-child
				border none
			.name
				display inline-block
				text-transform uppercase
				text-align left
				font-weight 700
				font-size 1.6rem
				color blue_lighter
				.ui-icon
					float left
					margin 0 8px 0 0
			.buttons
				display inline-block
				>>> .ui-button
					margin-left 8px
					font-weight 700
					text-transform uppercase
					&.open .button
						background-color blue
					.button
						border-radius 8px
						background-color #3FB143
						text-transform uppercase
						font-size 1.4rem
						padding 0 24px
						font-family 'Josefin Sans'
						padding-top 4px
						.loader
							border-radius 8px
							&.loading
								background-color #3FB143
						> span
							transform translateY(2px)


	
</style>
